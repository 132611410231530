<template>
  <div class="faqs">
    <nav-menu></nav-menu>
    <div class="page-heading">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 align-self-center">
            <div class="caption header-text">
              <h6>SEO DIGITAL AGENCY</h6>
              <div class="line-dec"></div>
              <h4>Most Frequently Asked <em>Questions</em> Here <em>?</em></h4>
            </div>
          </div>
          <div class="col-lg-5">
            <img
              src="assets/images/faqs-image.jpg"
              alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="happy-steps">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>Our 4 Steps To Success &amp; Happy Clients</h2>
          </div>
          <div class="col-lg-12">
            <div class="steps">
              <div class="row">
                <div class="col-lg-3">
                  <div class="item">
                    <img
                      src="assets/images/services-01.jpg"
                      alt=""
                      style="
                        max-width: 66px;
                        border-radius: 50%;
                        margin: 0 auto;
                      " />
                    <h4>Project Introduction</h4>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="item">
                    <img
                      src="assets/images/services-02.jpg"
                      alt=""
                      style="
                        max-width: 66px;
                        border-radius: 50%;
                        margin: 0 auto;
                      " />
                    <h4>Work Development</h4>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="item">
                    <img
                      src="assets/images/services-03.jpg"
                      alt=""
                      style="
                        max-width: 66px;
                        border-radius: 50%;
                        margin: 0 auto;
                      " />
                    <h4>Data Analysis</h4>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="item last-item">
                    <img
                      src="assets/images/services-04.jpg"
                      alt=""
                      style="
                        max-width: 66px;
                        border-radius: 50%;
                        margin: 0 auto;
                      " />
                    <h4>Project Finishing</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="most-asked section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-heading">
              <h2>Most <em>Frequently</em> Asked <span>Questions</span> ?</h2>
              <div class="line-dec"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                doers.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="accordions is-first-expanded">
              <article class="accordion">
                <div class="accordion-head">
                  <span>Sartorial Butcher Humblebrag</span>
                  <span class="icon">
                    <i class="icon fa fa-chevron-right"></i>
                  </span>
                </div>
                <div class="accordion-body">
                  <div class="content">
                    <p>
                      Tale is the best SEO agency website template using
                      Bootstrap v5.2.2 CSS for your company. It is a free
                      download provided by TemplateMo. There are 3 HTML pages,
                      <a href="index.html">Home</a>,
                      <a href="about.html">About</a>, and
                      <a href="faqs.html">FAQ</a>.
                    </p>
                  </div>
                </div>
              </article>
              <article class="accordion">
                <div class="accordion-head">
                  <span>Jean Shorts Microdosing</span>
                  <span class="icon">
                    <i class="icon fa fa-chevron-right"></i>
                  </span>
                </div>
                <div class="accordion-body">
                  <div class="content">
                    <p>
                      You are free to use this template for any purpose. You are
                      not allowed to redistribute the downloadable ZIP file of
                      Tale SEO Template on any other template website. Please
                      contact us. Thank you. <br /><br />
                      Semiotics blog cray letterpress lo-fi vexillologist before
                      they sold out swag YOLO schlitz. Coloring book roof party
                      gentrify brunch.
                    </p>
                  </div>
                </div>
              </article>
              <article class="accordion">
                <div class="accordion-head">
                  <span>Waistcoat Aesthetic Polaroid</span>
                  <span class="icon">
                    <i class="icon fa fa-chevron-right"></i>
                  </span>
                </div>
                <div class="accordion-body">
                  <div class="content">
                    <p>
                      Semiotics blog cray letterpress lo-fi vexillologist before
                      they sold out swag YOLO schlitz. Coloring book roof party
                      gentrify brunch.<br /><br />
                      Fingerstache cronut taxidermy, echo park quinoa tumblr
                      activated charcoal before they sold out.
                    </p>
                  </div>
                </div>
              </article>
              <article class="accordion">
                <div class="accordion-head">
                  <span>Dolores Accordion HTML5</span>
                  <span class="icon">
                    <i class="icon fa fa-chevron-right"></i>
                  </span>
                </div>
                <div class="accordion-body">
                  <div class="content">
                    <p>
                      Pickled succulents bitters belly direct trade, shaman
                      iceland raw denim kombucha cray offal. Food truck swag
                      hell of tumblr poutine tilde live-edge shorts microdosing
                      fixie succulents, viral everyday carry tattooed.
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="get-free-quote">
              <form
                id="free-quote"
                method="submit"
                role="search"
                action="#">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="section-heading">
                      <h2>Get a <em>Free Quote</em> Now</h2>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        pattern="[^ @]*@[^ @]*"
                        placeholder="Your E-mail"
                        required="" />
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <input
                        type="website"
                        name="website"
                        id="website"
                        placeholder="Website URL"
                        autocomplete="on"
                        required />
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <input
                        type="phone-number"
                        name="phone-number"
                        id="phone-number"
                        placeholder="Phone Number"
                        autocomplete="on"
                        required />
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <input
                        type="full-name"
                        name="full-name"
                        id="full-name"
                        placeholder="Full Name"
                        autocomplete="on" />
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <button
                        type="submit"
                        id="form-submit"
                        class="orange-button">
                        Get Your Free Quote
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cta section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <h4>
              Are You Ready To Work &amp; Develop With Us ?<br />Don't Hesitate
              &amp; Contact Us !
            </h4>
          </div>
          <div class="col-lg-4">
            <div class="main-button">
              <a href="#">Contact Us Now!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteFooter from '@/components/Footer.vue';
import navMenu from '@/components/NavMenu';

export default {
  name: 'Faqs',
  components: {
    SiteFooter,
    navMenu,
  },
};
</script>

<style></style>
